import MmenuLight from 'mmenu-light';

document.addEventListener('turbolinks:load', () => {
  const sidebarElement = document.querySelector('#sidebar');
  if (sidebarElement) {
    const sidebar = new MmenuLight(sidebarElement);
    const drawer = sidebar.offcanvas({});

    document.querySelector('#sidebarCollapse').addEventListener('click', (event) => {
      event.preventDefault();
      drawer.open();
    });

    document.addEventListener('turbolinks:before-cache', () => {
      drawer.close();
    });
  }
});
